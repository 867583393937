<template>
  <v-row>
    <v-col cols="12">
      <v-progress-linear
        v-if="b_Loading"
        class="my-1"
        indeterminate
        color="primary"
      />
      <base-card>
        <icon-class-bar
          title="Präsenzkontrolle"
          :marks="true"
          :comments="true"
          :presence="true"
          current="presence"
          @edit-mode="editMode=true"
        />
        <v-card-text>
          <div class="d-flex justify-space-between">
            <v-select
              v-model="i_CurrentSeminarCenterID"
              :items="a_SeminarCenter"
              item-text="s_Name"
              item-value="i_SeminarCenterID"
              filled
              label="Standort"
              dense
              outlined
              color="success"
              @change="setSeminarCenterID"
              class="mr-2"
              v-if="b_IsTeacherAdmin"
            />
            <div
              class=""
              cols="6"
              sm="5"
            >
              <v-select
                v-model="selectedClass"
                :items="a_ClassSubjectNames"
                item-text="s_Name"
                item-value="i_ClassSubjectID"
                filled
                label="Klasse"
                :no-data-text="s_DataText"
                :loading="b_LoadingClasses"
                dense
                outlined
                color="success"
                class="mr-4"
                @change="updateCurrentClass($event)"
              />
            </div>
            <div class="" cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="$smt.getSwissDate(s_ClassDate)"
                    label="Datum"
                    prepend-icon="mdi-calendar"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="s_ClassDate"
                  @input="menu2 = false"
                />
              </v-menu>
            </div>
            <div>
              <v-btn icon @click="getNearLessonDate('previous')">
                <v-icon size="50">
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn icon @click="getNearLessonDate('next')">
                <v-icon size="50">
                  mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
            <v-spacer />
          </div>
          <v-progress-linear
            v-if="b_LoadingParticipant"
            indeterminate
          ></v-progress-linear>
          <v-simple-table v-if="b_LessonsLoaded" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{$t('general.lastname')}}</th>
                  <th>{{$t('general.firstname')}}</th>
                  <th v-for="sublesson of a_Sublessons" v-bind:key="sublesson.i_LessonID + '-' + sublesson.i_SublessonID">
                    {{sublesson.s_SublessonName}}<br />{{sublesson.s_LessonStartTime}}<br />
                    <v-btn 
                      class="mb-1 white--text"
                      depressed
                      x-small
                      :disabled="checkLessonDate(sublesson)"
                      color='red darken-2'
                      :ref="getButtonLessonRef(sublesson.i_LessonID, sublesson.i_SublessonID)"
                      @click="setLessonPresenceList(
                        sublesson.i_LessonID,
                        sublesson.i_SublessonID,
                        0,
                        o_Config.presenceSettings.presenceType.absent.id)"
                    >
                      {{$t('all')}}
                      <v-icon
                        right
                        dark
                      >
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </th>
                </tr>
              </thead>
              <tbody v-if="b_ParticipantsLoaded">
                <tr v-for="(participant, index) of a_Participants" v-bind:key="participant.i_PersonID">
                  <th>{{ index + 1}}</th>
                  <td>{{ participant.s_Lastname }}</td>
                  <td>{{ participant.s_Firstname }}</td>
                  <td v-for="sublesson of a_Sublessons" 
                    v-bind:key="sublesson.i_LessonID + '-' + sublesson.i_SublessonID"
                  >
                    <v-btn 
                      icon 
                      x-small
                      :disabled="checkLessonDate(sublesson)"
                      :loading="b_LoadingLessonPresence && i_Btn==index"
                      :ref="getButtonRef(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID)"
                      @click="setLessonPresence(
                        participant.i_PersonID,
                        sublesson.i_LessonID,
                        sublesson.i_SublessonID,
                        0,
                        getPresenceType(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID),
                        index);
                        i_Btn=index"
                    >
                      <v-icon 
                        :disabled="!b_LessonsLoaded" 
                        size="27"
                        :color="getColorIcon(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID)" 
                      >
                        {{ getPresenceIcon(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID) }}
                      </v-icon>
                    </v-btn>
                    <v-input hide-details value="input" id="presencetype"></v-input>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import IconClassBar from '../../../components/base/IconClassBar.vue'
  import { get, post } from '../../../worker/worker-api'
  import { mapGetters, mapActions } from 'vuex'
  import { config } from '../../../data/config'

  export default {
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Präsenzkontrolle',
    },
    components: {
      IconClassBar,
    },
    data () {
      return {
        selectedClass: null,
        a_Studies: [],
        a_Classes: [],
        a_Courses: [],
        a_Sublessons: [],
        a_Excuses: [],
        a_Participants: [],
        a_PresenceType: config.components.presence.type,
        o_CurrentClass: {
          i_ClassGroupID: null,
          i_ClassSubjectID: null,
          s_Name: null,
        },
        i_CurrentClassID: null,
        b_LessonsLoaded: true,
        b_ParticipantsLoaded: true,
        search: '',
        selected: [],
        s_ClassDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10),
        menu: false,
        modal: false,
        menu2: false,
        b_IsTeacherAdmin: false,
        a_SeminarCenter: [],
        i_CurrentSeminarCenterID: null,
        b_LoadingParticipant: false,
        o_Config: config,
        b_Loading: true,
        b_LoadingClasses: true,
        b_ForceNearLesson: false,
        i_TacherAddressRoleID: null,
        b_LoadingLessonPresence: false,
        a_LoadingLessonPresence: [],
        i_Btn: -1,
      }
    },
    watch: {
      i_CurrentClassID: function (val) {
        this.b_ForceNearLesson = false
        if (val === null) return
        this.getClassLessons()
        this.getClassPresences()
      },
      o_CurrentClass: function (val) {
        if (val.i_ClassGroupID === null) return
        this.b_ForceNearLesson = false
        this.getClassLessons()
        this.getClassPresences()
      },
      s_ClassDate: function () {
        if (this.o_CurrentClass.i_ClassGroupID != null) {
          this.getClassLessons()
          this.getClassPresences()
        }
      },
      i_CurrentSeminarCenterID: function (val) {
        this.getTeacherStudies()
      },
      i_PlanningPeriodID () {
        this.b_Loading = true
        this.b_LoadingParticipant = false
        this.b_ForceNearLesson = false
        this.a_Studies = []
        this.a_Classes = []
        this.a_Courses = []
        this.a_Sublessons = []
        this.a_Participants = []
        this.selectedClass = null
        this.o_CurrentClass = {
          i_ClassGroupID: null,
          i_ClassSubjectID: null,
          s_Name: null,
        }
        this.i_CurrentClassID = null
        this.s_ClassDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
        this.getCourses()
        this.getTeacherStudies()
      }
    },
    computed: {
      ...mapGetters(['getPlanningPeriodID', 'getSeminarCenter', 'getImpersonation']),
      i_PlanningPeriodID () {
        return this.getPlanningPeriodID
      },
      a_ClassNames() {
        return this.a_Courses.map(o_Course => o_Course.title)
      },
      a_ClassSubjectNames() {
        const La_ClassSubjectNames = []
        if (this.b_IsTeacherAdmin) {
          if (this.i_CurrentSeminarCenterID === null) return []
          const La_SeminarCenterStudy = this.a_Studies.map(
            o_Study => {
              return {
                i_StudiesID: o_Study.i_StudiesID,
                s_Name: o_Study.s_Name,
                a_Class: o_Study.a_Class.filter(o_Class => {
                  return o_Class.i_SeminarCenterID === this.i_CurrentSeminarCenterID
                })
              }              
            }
          ) 
          La_SeminarCenterStudy.forEach(o_Study => {
            o_Study.a_Class.forEach(o_Class => {
              o_Class.a_ClassSubject.forEach(o_ClassSubject => {
                La_ClassSubjectNames.push({
                  s_Name: o_Class.s_Name + ' > ' + o_ClassSubject.s_Name,
                  i_ClassSubjectID: o_ClassSubject.i_ClassSubjectID,
                  i_ClassGroupID: o_ClassSubject.i_ClassGroupID
                })
              })
            })
          })
          return La_ClassSubjectNames.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
        }
        this.a_Studies.forEach(o_Study => {
          o_Study.a_Class.forEach(o_Class => {
            o_Class.a_ClassSubject.forEach(o_ClassSubject => {
              La_ClassSubjectNames.push({
                s_Name: o_Class.s_Name + ' > ' + o_ClassSubject.s_Name,
                i_ClassSubjectID: o_ClassSubject.i_ClassSubjectID,
                i_ClassGroupID: o_ClassSubject.i_ClassGroupID
              })
            })
          })
        })
        return La_ClassSubjectNames.sort((a, b) => a.s_Name > b.s_Name ? 1 : -1)
      },
      s_DataText () {
        if (this.b_LoadingClasses) return 'Loading data ...'
        else return 'No available data'
      },
      b_LoadingStatus () {
        console.log('b_LoadingStatus', this.a_LoadingLessonPresence)
        return this.a_LoadingLessonPresence
      },
    },
    methods: {
      ...mapActions(['signOut']),
      getClasses() {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/class`, {
        })
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_Classes = response;
            this.b_LoadingClasses = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getTeacherStudies () {
        this.b_LoadingClasses = true
        if (this.b_IsTeacherAdmin) {
          get(`${process.env.VUE_APP_SMT_API_URL}/data/period/studies`, {
            i_PlanningPeriodID: this.getPlanningPeriodID,
            b_CheckResultTemplate: 0,
            i_SeminarCenterID: this.i_CurrentSeminarCenterID
          })
            .then(response => { 
              if (response.status === 401) {
                //this.signOut()
                //this.$smt.logout()
                return
              }
              this.a_Studies = response
              this.b_LoadingClasses = false
            })
            .catch(error => {
              console.log('Worker error: ', error)
            })
        }
        else {
          get(`${process.env.VUE_APP_SMT_API_URL}/teacher/period/studies`, {
            i_AddressRoleID: this.i_TacherAddressRoleID,
            i_PlanningPeriodID: this.getPlanningPeriodID,
            b_CheckResultTemplate: 0,
          })
            .then(response => { 
              if (response.status === 401) {
                //this.signOut()
                //this.$smt.logout()
                return
              }
              this.a_Studies = response
              this.b_LoadingClasses = false
            })
            .catch(error => {
              console.log('Worker error: ', error)
            })
        }
      },
      getCourses () {
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/courses`, {
        })
          .then(response => { 
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.a_Courses = response
            this.b_Loading = false
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getClassPresences () {
        this.b_LoadingParticipant = true
        this.a_Participants.forEach(participant => { participant.a_Presences = [] })
        //let classGroupID = this.a_Courses.filter(o_Course => o_Course.title === this.selectedClass)[0].i_ClassGroupID
        let classGroupID = this.o_CurrentClass.i_ClassGroupID
        if(classGroupID === null) {
          this.b_LoadingParticipant = false
          return [];
        }
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/class/group/${classGroupID}/presence`, {
          s_Date: this.s_ClassDate,
          i_ClassSubjectID: this.o_CurrentClass.i_ClassSubjectID,
        })
          .then(response => {
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.b_LoadingParticipant = false
            this.a_Participants = response.sort((a, b) => a.s_Lastname > b.s_Lastname ? 1 : -1)
            this.b_ParticipantsLoaded = true
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getClassLessons () {
        let Ls_SublessonName, Ls_LessonSublessonID, Li_SublessonID, Li_LessonID
        this.a_Lessons = []
        this.a_Sublessons = []
        this.b_LessonsLoaded = false;
        let classGroupID = this.o_CurrentClass.i_ClassGroupID
        if(classGroupID === null) {
          return [];
        }
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/class/group/${classGroupID}/lesson`, {
          s_Date: this.s_ClassDate,
          i_ClassSubjectID: this.o_CurrentClass.i_ClassSubjectID,
        })
          .then(response => {
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            this.a_Lessons = response.sort((a, b) => a.i_LessonStartTime > b.i_LessonStartTime ? 1 : -1)
            if (this.a_Lessons.length < 1 && !this.b_ForceNearLesson) {
              this.b_ForceNearLesson = true
              this.getNearLessonDate ('previous')
              return
            }
            this.b_ForceNearLesson = false
            this.a_Lessons.forEach((lesson) => {
              lesson.a_Sublessons.forEach((sublesson)=> {
                if (sublesson.s_Name === '') Ls_SublessonName = lesson.s_LessonName;
                else Ls_SublessonName = lesson.s_LessonName + ' (' + sublesson.s_Name + ')';
                Ls_LessonSublessonID = lesson.i_SSB_LessonID + '-' + sublesson.i_SublessonID;
                Li_SublessonID = sublesson.i_SublessonID;
                Li_LessonID = lesson.i_SSB_LessonID;
                this.a_Sublessons.push({
                  's_SublessonName': Ls_SublessonName,
                  's_LessonSublessonID': Ls_LessonSublessonID,
                  'i_SublessonID': Li_SublessonID,
                  'i_LessonID': Li_LessonID,
                  's_LessonDate': lesson.s_LessonDate,
                  's_LessonStartTime': lesson.s_LessonStartTime
                });
              });
            });
            this.b_LessonsLoaded = true;
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      getNearLessonDate (As_Action) {
        let classGroupID = this.o_CurrentClass.i_ClassGroupID
        if(classGroupID === null) return [];
        get(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/class/${classGroupID}/lesson/near-date`, {
          s_Date: this.s_ClassDate,
          s_Action: As_Action,
          i_ClassSubjectID: this.o_CurrentClass.i_ClassSubjectID,
        })
          .then(response => {
            if (response.status === 401) {
              //this.signOut()
              //this.$smt.logout()
              return
            }
            if (response.length >= 10) this.s_ClassDate = response.substr(0, 10)
          })
          .catch(error => {
            console.log('Worker error: ', error)
          })
      },
      updateCurrentClass (event) {
        this.o_CurrentClass = this.a_ClassSubjectNames.filter(o_ClassSubject => o_ClassSubject.i_ClassSubjectID === event)[0]
        this.s_ClassDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
      },
      updateCurrentClassID () {
        let classGroupID = this.a_Courses.filter(o_Course => o_Course.title === this.selectedClass)[0].i_ClassGroupID;
        this.i_CurrentClassID = classGroupID;
        this.s_ClassDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 10)
      },
      getPresenceObject (Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        let Lo_Presence = null 
        const La_Participants = this.a_Participants.filter(participant => participant.i_PersonID === Ai_PersonID);
        let Lo_Participant;
        let La_PresencesByLesson;
        let La_PresencesBySublesson;

        if(La_Participants.length > 0) {
          Lo_Participant = La_Participants[0];
          La_PresencesByLesson = Lo_Participant.a_Presences.filter(presence => presence.i_SSB_LessonID === Ai_LessonID);
          La_PresencesBySublesson = La_PresencesByLesson.filter(subpresence => subpresence.i_SublessonID === Ai_SublessonID);
          if(La_PresencesBySublesson.length > 0) {
            Lo_Presence = La_PresencesBySublesson[0];
          }
        }  
        return Lo_Presence;
      },  
      getPresenceIcon (Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        let Ls_PresenceIcon = config.presenceSettings.presenceType[config.presenceSettings.defaultType].icon 
        let Lo_Presence
        Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID)
        if(Lo_Presence) {
          const La_PresenceIcon = Object.values(config.presenceSettings.presenceType).filter(value => {
            return value.id === Lo_Presence.i_SSN_PresenceTypeID
          })
          if (La_PresenceIcon.length > 0) Ls_PresenceIcon = La_PresenceIcon[0].icon
        }
        return Ls_PresenceIcon;
      },
      getPresenceID (Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        let Li_PresenceID = 0
        let Lo_Presence
        Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        if(Lo_Presence) {
          Li_PresenceID = Lo_Presence.i_SSN_PresenceID;
        }
        return Li_PresenceID;
      },
      setLessonPresence (Ai_PersonID, Ai_LessonID, Ai_SublessonID, Ai_PresenceFlag,  As_PresenceType, Ai_BtnIndex) {
        this.a_LoadingLessonPresence[Ai_BtnIndex] = true
        const Ls_ButtonRef = this.getButtonRef(Ai_PersonID, Ai_LessonID, Ai_SublessonID)
        this.b_LoadingLessonPresence = true
        let Li_PresenceID = this.getPresenceID(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        let Ls_PresenceType = As_PresenceType === config.presenceType.present
          ? config.presenceType.absent
          : config.presenceType.present
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/class/lesson/presence`, {
          i_PersonID: Ai_PersonID,
          i_ClassID: this.o_CurrentClass.i_ClassGroupID,
          i_LessonID: Ai_LessonID,
          i_SublessonID: Ai_SublessonID,
          i_PresenceFlag: Ai_PresenceFlag,
          s_PresenceType: Ls_PresenceType,
          i_PresenceID: Li_PresenceID,
          s_Date: this.s_ClassDate
        }).then(
          response => {
            this.updatePresenceType(Ai_PersonID, Ai_LessonID, Ai_SublessonID, Ls_PresenceType, Li_PresenceID, response.i_PresenceID)
            this.$refs[Ls_ButtonRef][0].$options.propsData.loading = false
            this.a_LoadingLessonPresence[Ai_BtnIndex] = false
            this.b_LoadingLessonPresence =  false
          }
        ).catch(
          error => {
            this.a_LoadingLessonPresence[Ai_BtnIndex] = false
            this.b_LoadingLessonPresence = false
          }
        );
      },
      setLessonPresenceList (Ai_LessonID, Ai_SublessonID, Ai_PresenceFlag,  Ai_PresenceTypeID) {
        if (this.setPresenceOnWork) return
        this.b_LoadingParticipant = true
        const Ls_ButtonLessonRef = this.getButtonLessonRef(Ai_LessonID, Ai_SublessonID)
        this.$refs[Ls_ButtonLessonRef][0].$el.disabled = true
        this.setPresenceOnWork = true
        let La_StudentData = []
        let Li_PresenceID
        this.a_Participants.forEach(participant => {
          Li_PresenceID = this.getPresenceID(participant.i_PersonID, Ai_LessonID, Ai_SublessonID)
          La_StudentData.push({
            i_PersonID: participant.i_PersonID,
            i_AddressRoleID: participant.i_AddressRoleID,
            o_PresenceData: {
              i_PresenceFlag: Ai_PresenceFlag,
              i_PresenceTypeID: Ai_PresenceTypeID,
              i_PresenceID: Li_PresenceID,
            }
          })
        })
        const Lo_StudentPresence = {
          i_ClassID: this.o_CurrentClass.i_ClassGroupID,
          s_Date: this.s_ClassDate,
          i_LessonID: Ai_LessonID,
          i_SublessonID: Ai_SublessonID,
          a_Student: La_StudentData,
        }
        post(`${process.env.VUE_APP_SMT_API_URL}/teacher/${this.i_TacherAddressRoleID}/period/${this.getPlanningPeriodID}/class/lesson/presences`, {
          o_StudentPresence: Lo_StudentPresence
        }).then(
          response => {
            this.b_LoadingParticipant = false
            this.setPresenceOnWork = false;
            this.updateParticipantPresence(response)
            this.$refs[Ls_ButtonLessonRef][0].$el.disabled = false
          }
        ).catch(
          error => {
            this.setPresenceOnWork = false;
          }
        );
      },
      getButtonRef(Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        return "s_Ref_" + Ai_PersonID + "_" + Ai_LessonID + "_" + Ai_SublessonID
      },
      getButtonLessonRef(Ai_LessonID, Ai_SublessonID) {
        return "s_Ref_" + "_" + Ai_LessonID + "_" + Ai_SublessonID
      },
      getPresenceComment: function(Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        let Ls_Comment = '';
        let Li_PresenceID = 0;
        const La_Participants = this.a_Participants.filter(participant => participant.i_PersonID === Ai_PersonID);
        let Lo_Participant;
        const searchSublesson = (sublesson) => sublesson.i_LessonID == Ai_LessonID && sublesson.i_SublessonID == Ai_SublessonID;
        let Li_IndexSublesson = this.a_Sublessons.findIndex(searchSublesson);
        let Ls_SublessonName = this.a_Sublessons[Li_IndexSublesson].s_SublessonName;
        let Lo_Presence;
        
        if(La_Participants.length > 0) {
          Lo_Participant = La_Participants[0];
          Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
          if(Lo_Presence) {
            Ls_Comment = Lo_Presence.s_Comment;
            Li_PresenceID = Lo_Presence.i_SSN_PresenceID;
          }
        } 
        this.o_CurrentComment.s_Comment = Ls_Comment;
        this.o_CurrentComment.s_ParticipantName = Lo_Participant.s_Firstname + ' ' + Lo_Participant.s_Lastname;
        this.o_CurrentComment.i_PersonID = Ai_PersonID;
        this.o_CurrentComment.s_LessonName = Ls_SublessonName;
        this.o_CurrentComment.i_LessonID = Ai_LessonID;
        this.o_CurrentComment.i_SublessonID = Ai_SublessonID;
        this.o_CurrentComment.i_SSN_PresenceID = Li_PresenceID;
      },
      setPresenceComment(Ao_CurrentComment) {
        let Li_ApiPresenceID;
        axios.post(sessionStorage.base_url + '/api/course/presencecomment', {
          o_PresenceComment: Ao_CurrentComment
        }).then(
          response => {
            Li_ApiPresenceID = response.data.i_PresenceID;
            this.updatePresenceComment(Ao_CurrentComment.i_PersonID, Ao_CurrentComment.i_LessonID, Ao_CurrentComment.i_SublessonID, 
              Ao_CurrentComment.s_Comment, Ao_CurrentComment.i_SSN_PresenceID, Li_ApiPresenceID)
          }
        ).catch(
          error => {
          }
        );               
      },
      updatePresenceComment(Ai_PersonID, Ai_LessonID, Ai_SublessonID, As_Comment, Ai_LocalPresenceID, Ai_ApiPresenceID) {
        let Li_PresenceIndex;
        const Li_ParticipantIndex = this.a_Participants.findIndex(participant => participant.i_PersonID === Ai_PersonID);
        const searchPresence = (presence) => presence.i_SSB_LessonID == Ai_LessonID && presence.i_SublessonID == Ai_SublessonID;

        if(Ai_LocalPresenceID === 0) {
          this.a_Participants[Li_ParticipantIndex].a_Presences.push({
            'i_SSB_LessonID': Ai_LessonID,
            'i_SublessonID': Ai_SublessonID,
            'i_PresenceFlag': 0,
            'i_SSN_PresenceID':  Ai_ApiPresenceID,
            'i_SSN_PresenceTypeID': 10,
            's_Comment': As_Comment
          });
        }
        Li_PresenceIndex = this.a_Participants[Li_ParticipantIndex].a_Presences.findIndex(searchPresence);
        this.a_Participants[Li_ParticipantIndex].a_Presences[Li_PresenceIndex].s_Comment = As_Comment;
      },
      getPresenceType (Ai_PersonID, Ai_LessonID, Ai_SublessonID) {
        const La_PresenceTypeText = this.a_PresenceType.filter(presenceType => presenceType.default === true)
        let Li_PresenceTypeText = La_PresenceTypeText[0].s_Name
        let Lo_Presence
        let La_PresenceType
        Lo_Presence = this.getPresenceObject(Ai_PersonID, Ai_LessonID, Ai_SublessonID)
        if (Lo_Presence) {
          La_PresenceType = this.a_PresenceType.filter(presenceType => presenceType.i_PresenceTypeID == Lo_Presence.i_SSN_PresenceTypeID);  
          Li_PresenceTypeText = La_PresenceType[0].s_Name
        }
        return Li_PresenceTypeText
      },
      updatePresenceType(Ai_PersonID, Ai_LessonID, Ai_SublessonID, As_PresenceType, Ai_LocalPresenceID, Ai_ApiPresenceID){
        let Li_PresenceIndex;
        const Li_ParticipantIndex = this.a_Participants.findIndex(participant => participant.i_PersonID === Ai_PersonID)
        if (Li_ParticipantIndex === -1) return
        let Li_PresenceTypeID = this.getPresenceTypeID(As_PresenceType).i_PresenceTypeID;
        const searchPresence = (presence) => presence.i_SSB_LessonID === Ai_LessonID && presence.i_SublessonID === Ai_SublessonID    
        Li_PresenceIndex = this.a_Participants[Li_ParticipantIndex].a_Presences.findIndex(searchPresence);
        if(Li_PresenceIndex === -1) {
          this.a_Participants[Li_ParticipantIndex].a_Presences.push({
            'i_SSB_LessonID': Ai_LessonID,
            'i_SublessonID': Ai_SublessonID,
            'i_PresenceFlag': 0,
            'i_SSN_PresenceID':  Ai_ApiPresenceID,
            'i_SSN_PresenceTypeID': Li_PresenceTypeID,
            's_Comment': ''
          });
        } else {
          this.a_Participants[Li_ParticipantIndex].a_Presences[Li_PresenceIndex].i_SSN_PresenceTypeID = Li_PresenceTypeID
        }
      },
      updateParticipantPresence(Ao_StudentPresence){
        let Li_PresenceIndex
        let Li_ParticipantIndex
        const searchPresence = (presence) => presence.i_SSB_LessonID === Ao_StudentPresence.i_LessonID && presence.i_SublessonID === Ao_StudentPresence.i_SublessonID
        Ao_StudentPresence.a_Student.forEach(student => {
          Li_ParticipantIndex = this.a_Participants.findIndex(participant => participant.i_PersonID === student.i_PersonID)
          if (Li_ParticipantIndex === -1) return
          Li_PresenceIndex = this.a_Participants[Li_ParticipantIndex].a_Presences.findIndex(searchPresence)
          if (Li_PresenceIndex === -1) {
            this.a_Participants[Li_ParticipantIndex].a_Presences.push({
              'i_SSB_LessonID': Ao_StudentPresence.i_LessonID,
              'i_SublessonID': Ao_StudentPresence.i_SublessonID,
              'i_PresenceFlag': student.o_PresenceData.i_PresenceFlag,
              'i_SSN_PresenceID':  student.o_PresenceData.i_PresenceID,
              'i_SSN_PresenceTypeID': student.o_PresenceData.i_PresenceTypeID,
              's_Comment': ''
            });
          } else {
            this.a_Participants[Li_ParticipantIndex].a_Presences[Li_PresenceIndex].i_SSN_PresenceTypeID = student.o_PresenceData.i_PresenceTypeID
          }
        })
      },
      getPresenceTypeID(As_PresenceTypeText) {
        let Li_PresenceTypeIndex;
        
        Li_PresenceTypeIndex = this.a_PresenceType.findIndex(presenceType => presenceType.s_Name == As_PresenceTypeText);
        
        return this.a_PresenceType[Li_PresenceTypeIndex];
      },
      getColorIcon(Ai_PersonID, Ai_LessonID, Ai_SublessonID){
        let Ls_ColorIcon;
        let Li_PresenceIcon = this.getPresenceIcon(Ai_PersonID, Ai_LessonID, Ai_SublessonID);
        
        switch(Li_PresenceIcon){
          case 'mdi-check-circle':
            Ls_ColorIcon = 'green darken-2';
            break;
          case 'mdi-close-circle':
            Ls_ColorIcon = 'red darken-2';
            break;   
          default:
            Ls_ColorIcon = 'red darken-2';
            break;
        }
        
        return Ls_ColorIcon;
      },
      fillExcuses () {
        this.a_PresenceType.forEach(
          presenceType => {
            this.a_Excuses.push(presenceType.s_Name);
          }
        );
      },
      checkLessonDate (sublesson) {
        const La_LessonDate = sublesson.s_LessonDate.split('.')
        const La_LessonTime = sublesson.s_LessonStartTime.split(':')
        const Lt_LessonDate = new Date( La_LessonDate[2], parseInt(La_LessonDate[1]) - 1, La_LessonDate[0], La_LessonTime[0], La_LessonTime[1])
        const Lt_LessonDatePlus15Min = new Date(Date.now() + 1000*60*15)
        if (Lt_LessonDate <= Lt_LessonDatePlus15Min) return false
        return true
      },
      setSeminarCenterID (val) {
        this.i_CurrentSeminarCenterID = val
      },
    },
    beforeMount() {
      if (this.getImpersonation.b_Status && this.getImpersonation.s_Role === config.roles[1]) this.i_TacherAddressRoleID = this.getImpersonation.i_AddressRoleID
      else this.i_TacherAddressRoleID = this.$smt.getAddressRoleID(this.$route.meta.role);
      this.getCourses()
      this.a_SeminarCenter = this.getSeminarCenter
      this.b_IsTeacherAdmin = this.a_SeminarCenter.length > 0 && !this.getImpersonation.b_Status
      if (this.a_SeminarCenter.length > 0) {
        this.i_CurrentSeminarCenterID = this.a_SeminarCenter[0].i_SeminarCenterID
      }
      if (!this.b_IsTeacherAdmin) this.getTeacherStudies()
    },
  }
</script>
<style lang="scss" scoped>
::v-deep .table-one {
    thead.v-data-table-header {
        tr {
            &:hover {
                background-color: #f2f3f8;
            }
            th {
                span {
                    font-size: 16px;
                    color: #304156;
                }
            }
        }
        tr {
            td {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }
    tbody {
        tr {
            &:hover {
                background-color: #f2f3f8 !important;
            }
        }
    }
}
</style>
