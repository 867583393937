var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.b_Loading)?_c('v-progress-linear',{staticClass:"my-1",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_c('base-card',[_c('icon-class-bar',{attrs:{"title":"Präsenzkontrolle","marks":true,"comments":true,"presence":true,"current":"presence"},on:{"edit-mode":function($event){_vm.editMode=true}}}),_c('v-card-text',[_c('div',{staticClass:"d-flex justify-space-between"},[(_vm.b_IsTeacherAdmin)?_c('v-select',{staticClass:"mr-2",attrs:{"items":_vm.a_SeminarCenter,"item-text":"s_Name","item-value":"i_SeminarCenterID","filled":"","label":"Standort","dense":"","outlined":"","color":"success"},on:{"change":_vm.setSeminarCenterID},model:{value:(_vm.i_CurrentSeminarCenterID),callback:function ($$v) {_vm.i_CurrentSeminarCenterID=$$v},expression:"i_CurrentSeminarCenterID"}}):_vm._e(),_c('div',{attrs:{"cols":"6","sm":"5"}},[_c('v-select',{staticClass:"mr-4",attrs:{"items":_vm.a_ClassSubjectNames,"item-text":"s_Name","item-value":"i_ClassSubjectID","filled":"","label":"Klasse","no-data-text":_vm.s_DataText,"loading":_vm.b_LoadingClasses,"dense":"","outlined":"","color":"success"},on:{"change":function($event){return _vm.updateCurrentClass($event)}},model:{value:(_vm.selectedClass),callback:function ($$v) {_vm.selectedClass=$$v},expression:"selectedClass"}})],1),_c('div',{attrs:{"cols":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$smt.getSwissDate(_vm.s_ClassDate),"label":"Datum","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.s_ClassDate),callback:function ($$v) {_vm.s_ClassDate=$$v},expression:"s_ClassDate"}})],1)],1),_c('div',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getNearLessonDate('previous')}}},[_c('v-icon',{attrs:{"size":"50"}},[_vm._v(" mdi-chevron-left ")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.getNearLessonDate('next')}}},[_c('v-icon',{attrs:{"size":"50"}},[_vm._v(" mdi-chevron-right ")])],1)],1),_c('v-spacer')],1),(_vm.b_LoadingParticipant)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_vm._e(),(_vm.b_LessonsLoaded)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t('general.lastname')))]),_c('th',[_vm._v(_vm._s(_vm.$t('general.firstname')))]),_vm._l((_vm.a_Sublessons),function(sublesson){return _c('th',{key:sublesson.i_LessonID + '-' + sublesson.i_SublessonID},[_vm._v(" "+_vm._s(sublesson.s_SublessonName)),_c('br'),_vm._v(_vm._s(sublesson.s_LessonStartTime)),_c('br'),_c('v-btn',{ref:_vm.getButtonLessonRef(sublesson.i_LessonID, sublesson.i_SublessonID),refInFor:true,staticClass:"mb-1 white--text",attrs:{"depressed":"","x-small":"","disabled":_vm.checkLessonDate(sublesson),"color":"red darken-2"},on:{"click":function($event){return _vm.setLessonPresenceList(
                      sublesson.i_LessonID,
                      sublesson.i_SublessonID,
                      0,
                      _vm.o_Config.presenceSettings.presenceType.absent.id)}}},[_vm._v(" "+_vm._s(_vm.$t('all'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-close ")])],1)],1)})],2)]),(_vm.b_ParticipantsLoaded)?_c('tbody',_vm._l((_vm.a_Participants),function(participant,index){return _c('tr',{key:participant.i_PersonID},[_c('th',[_vm._v(_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(participant.s_Lastname))]),_c('td',[_vm._v(_vm._s(participant.s_Firstname))]),_vm._l((_vm.a_Sublessons),function(sublesson){return _c('td',{key:sublesson.i_LessonID + '-' + sublesson.i_SublessonID},[_c('v-btn',{ref:_vm.getButtonRef(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID),refInFor:true,attrs:{"icon":"","x-small":"","disabled":_vm.checkLessonDate(sublesson),"loading":_vm.b_LoadingLessonPresence && _vm.i_Btn==index},on:{"click":function($event){_vm.setLessonPresence(
                      participant.i_PersonID,
                      sublesson.i_LessonID,
                      sublesson.i_SublessonID,
                      0,
                      _vm.getPresenceType(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID),
                      index);
                      _vm.i_Btn=index}}},[_c('v-icon',{attrs:{"disabled":!_vm.b_LessonsLoaded,"size":"27","color":_vm.getColorIcon(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID)}},[_vm._v(" "+_vm._s(_vm.getPresenceIcon(participant.i_PersonID, sublesson.i_LessonID, sublesson.i_SublessonID))+" ")])],1),_c('v-input',{attrs:{"hide-details":"","value":"input","id":"presencetype"}})],1)})],2)}),0):_vm._e()]},proxy:true}],null,false,4253081166)}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }